let rutas = [];

export default (async function() {
	let constelacion = await import('@/apps/constelacion/routes');
	rutas = rutas.concat(constelacion);
	
	let account = await import('@/apps/account/routes');
	rutas = rutas.concat(account);

	let tesoreria = await import('@/apps/tesoreria/routes');
	rutas = rutas.concat(tesoreria);

	let comite = await import('@/apps/comite/routes');
	rutas = rutas.concat(comite);

	let formalizacion = await import('@/apps/formalizacion/routes');
	rutas = rutas.concat(formalizacion);

	let garantias = await import('@/apps/garantias/routes');
	rutas = rutas.concat(garantias);

	let avaluodigital = await import('@/apps/avaluodigital/routes');
	rutas = rutas.concat(avaluodigital);

	let clientes = await import('@/apps/clientes/routes');
	rutas = rutas.concat(clientes);

	let inversionistas = await import('@/apps/inversionistas/routes');
	rutas = rutas.concat(inversionistas);

	let metricas = await import('@/apps/metricas/routes');
	rutas = rutas.concat(metricas);

	let cobranza_admin = await import('@/apps/cobranza_admin/routes');
	rutas = rutas.concat(cobranza_admin);

	let valija = await import('@/apps/valija/routes');
	rutas = rutas.concat(valija);

	let solicitudes = await import('@/apps/solicitudes/routes');
	rutas = rutas.concat(solicitudes);

	let tickets = await import('@/apps/tickets/routes');
	rutas = rutas.concat(tickets);

	let fyc = await import('@/apps/fyc/routes');
	rutas = rutas.concat(fyc);

	let analyticpoint = await import('@/apps/analyticpoint/routes');
	rutas = rutas.concat(analyticpoint);

	let alianzas_logs = await import('@/apps/alianzas_logs/routes');
	rutas = rutas.concat(alianzas_logs);

	let condusef = await import('@/apps/condusef/routes');
	rutas = rutas.concat(condusef);

	rutas = rutas.concat({default:[
		{
			path: '*'
			,name: 'constelacion_error_404'
			,component: () => import('@/apps/constelacion/pages/404')
			,meta: {
				title: 'Error 404'
			}
		}
	]});

	return rutas;
})();

